import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  HeaderAnoGlobal,
  LoaderComponent,
  LoaderModalComponent,
} from "app/shared/components";
import { Theme } from "app/shared/utils";
import { AnexosForm, } from "./components";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useApp, useRole, useService } from "app/shared/hooks";
import { alteraRecurso, buscaMacroAcoesEscola, buscaRecursoPorId, buscaRecursos } from "app/services/recurso";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RecursoStatus, TipoAnexo } from "app/shared/constants";
import {
  formataRequestRecurso,
  formateResponseRecurso,
  formatResponseMacroAcoes,
  isValidForm,
} from "./utils";
import toastr from "toastr";
import AddFilesList from "./components/AnexosForm/AddFilesList";
import AnexosImgPreview from "./components/AnexosForm/AnexosImgPreview";
import { clearCache } from "app/redux/reducers/cacheSlice";
import { useDispatch } from "react-redux";
import MacroAcaoListForm from "./components/MacroAcaoListForm";

const RecursoCadastroPage = () => {
  const dispatch = useDispatch();
  const { user, ano } = useApp();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const tipoPlanoId = query.get("tipoPlanoId");
  const planoLabel = query.get("planoLabel");
  const parcelaLabel = query.get("parcelaLabel");
  const parcelaId = query.get("parcelaId");
  const hasNotPlano = !id;
  const navigate = useNavigate();
  const { isRede } = useRole();
  const [isLoadingAnexos, setIsLoadingAnexos] = useState(true);

  const formMethods = useForm({
    values: {
      itens: [],
      anexos: [],
      macroAcoes: [],
    },
    mode: "all",
  });
  const { control } = formMethods;

  const handleSuccessSave = () => {
    //LIMPA CACHE DAS LISTAGENS
    dispatch(clearCache("getParcelasSemPlanoAll"))
    dispatch(clearCache("getParcelasPlanoAtualAll"))

    toastr.success(
      hasNotPlano ? "Plano criado com sucesso!" : "Plano editado com sucesso!"
    );
    navigate("/recurso");
  };

  const handleSucessGetMacroAcoes = async (data) => {
   const macroAcoes = await formatResponseMacroAcoes(data);
    formMethods.setValue("macroAcoes", macroAcoes)
  }

  const handleSuccessGetData = async (data) => {
    const formated = await formateResponseRecurso(data);
    setIsLoadingAnexos(false);
    formMethods.reset(formated);
  };

  const handleSuccessGetRecursoAnterior = (data) => {
    let tamanho = data.length
    if (!tamanho) {
      return
    }
    let { objetivo_estrategico, meta, justificativa } = data[tamanho - 1];
    formMethods.setValue("anexos", []);
    formMethods.setValue("itens", []);
    formMethods.setValue("objetivo_estrategico", objetivo_estrategico);
    formMethods.setValue("meta", meta);
    formMethods.setValue("justificativa", justificativa);
  }

  const {
    request: getRecursoAnterior,
    response: responseRecursoAnterior,
    retry: retryRecursoAnterior,
  } = useService(buscaRecursos, false, handleSuccessGetRecursoAnterior);

  const {
    request: getRecursoPorIdReq,
    response: { data, loading, error },
    retry: retryBuscaRecursoPorId,
  } = useService(buscaRecursoPorId, false, handleSuccessGetData);

  const {
    request: getMacroAcoesEscola,
    response: responseMacroAcoesEscola,
    retry: retryBuscaMacroAcoesEscola,
  } = useService(buscaMacroAcoesEscola, false, handleSucessGetMacroAcoes);

  const {
    request: alteraRecursoReq,
    response: responseAlteraRecurso,
    retry: retryAlteraRecurso,
  } = useService(alteraRecurso, false, handleSuccessSave);

  const getRecurso = () => {
    if (!id) {
      setIsLoadingAnexos(false);
      getMacroAcoesEscola({
        parcela_id: parcelaId,
        with: "itens,macro_acao",
        limit: 0
      })
      getDadosParcelaAnterior();
      return;
    }
   
    getRecursoPorIdReq({
      id: id,
      with: "parcela,escola.superintendencia,anexos,macro_acao_escolas.macro_acao,macro_acao_escolas.itens.situacao",
    });
  };

  const verificaRole = () => {
    if (isRede) {
      navigate("/dashboard");
    }
  };

  const getDadosParcelaAnterior = () => {
    let parcela = parseInt(parcelaLabel)
    if (parcela === 1) {
      return
    }
    let params = {
      tipo_plano_id: tipoPlanoId,
      "parcela[parcela]": parcela - 1,
      "parcela[ano]": ano,
      limit: 0,
    }
    getRecursoAnterior(params)
  }


  useEffect(() => {
    verificaRole();
    getRecurso();
  }, [id, isRede]);

  const handleSubmit = async (form) => {
    if (!isValidForm(form)) return;
    const formatedPayload = formataRequestRecurso({
      ...form,
      parcelaId,
      escola_id: user.relacionado.id,
    });
   alteraRecursoReq(formatedPayload);
  };

  const parcela = data?.parcela.parcela;
  if ((!parcela && !hasNotPlano) || isLoadingAnexos)
    return (
      <Box my="20px" padding={2}>
        <LoaderComponent
          loading={loading || isLoadingAnexos}
          error={error}
          retry={retryBuscaRecursoPorId}
          message="Buscando dados do recurso, aguarde um momento"
        />
      </Box>
    );

  const isEdit = data?.status !== RecursoStatus.PENDENTE;
  const headerTitle = !hasNotPlano ? "Editando recurso" : "Cadastro de recurso";

  const TiposAnexo = [
    { label: 'Propostas', tipo: TipoAnexo.PROPOSTA, CustomBody: AddFilesList, defaultExpanded: true },
    { label: 'Nota fiscal', tipo: TipoAnexo.NOTA_FISCAL, CustomBody: AddFilesList },
    { label: 'Fotos', tipo: TipoAnexo.FOTOS, CustomBody: AnexosImgPreview },
  ]

  return (
    <>
      {(responseAlteraRecurso.loading || responseAlteraRecurso.error) && (
        <LoaderModalComponent
          {...responseAlteraRecurso}
          retry={retryAlteraRecurso}
        />
      )}
      <LoaderComponent
        {...responseRecursoAnterior}
        retry={retryRecursoAnterior}
        message="Buscando parcela anterior"
        errorMessage="Falha ao carregar parcela anterior"
      />
      <LoaderComponent
        {...responseMacroAcoesEscola}
        retry={retryBuscaMacroAcoesEscola}
        message="Buscando ações..."
        errorMessage="Falha ao carregar ação"
      />
      <HeaderAnoGlobal
        showAnoSelect={false}
        hasBackButton
        titulo={headerTitle}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Box component={Paper} my="20px" padding={2}>
            <Grid container mt="10px" spacing={1}>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    shrink={true}
                    id="demo-simple-select-label-status"
                  >
                    Plano
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-status"
                    id="demo-simple-select-status"
                    value="plano"
                    notched={true}
                    disabled
                    size="small"
                    displayEmpty
                    label="Plano"
                  >
                    <MenuItem value="plano">{planoLabel}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="label-parcela">
                    Parcela
                  </InputLabel>
                  <Select
                    labelId="label-parcela"
                    value="parcela"
                    notched={true}
                    disabled
                    size="small"
                    displayEmpty
                    label="Plano"
                  >
                    <MenuItem value="parcela">{parcelaLabel}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction="column" mt="20px">
              <Typography
                variant="body1"
                fontWeight="bold"
                color={Theme.colors.blue.dark}
              >
                OBJETIVO ESTRATÉGICO*
              </Typography>
              <TextField
                disabled={responseRecursoAnterior?.loading}
                fullWidth
                id="outlined-textarea"
                multiline
                {...formMethods.register("objetivo_estrategico")}
                minRows={3}
              />
            </Grid>
            <Grid container mt="20px" spacing={1}>
              <Grid item md={6} sm={12} xs={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={Theme.colors.blue.dark}
                >
                  META*
                </Typography>
                <TextField
                  disabled={responseRecursoAnterior?.loading}
                  fullWidth
                  id="outlined-textarea"
                  multiline
                  {...formMethods.register("meta")}
                  minRows={3}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={Theme.colors.blue.dark}
                >
                  JUSTIFICATIVA*
                </Typography>
                <TextField
                  disabled={responseRecursoAnterior?.loading}
                  fullWidth
                  id="outlined-textarea"
                  multiline
                  {...formMethods.register("justificativa")}
                  minRows={3}
                />
              </Grid>
            </Grid>
            <Controller
              control={control}
              name="macroAcoes"
              render={({ field: { onChange, value } }) => (
                <MacroAcaoListForm macroAcoes={value} onChange={onChange} />
              )}
            />
            <Controller
              control={control}
              name="anexos"
              render={({ field: { onChange, value } }) => (
                <AnexosForm defaultValue={value} onChange={onChange} tipos={TiposAnexo} />
              )}
            />
            <Grid container justifyContent="end" my="20px">
              <Button
                style={{
                  justifySelf: "flex-end",
                }}
                variant="contained"
                type="submit"
                disabled={responseRecursoAnterior?.loading}
              >
                SALVAR
              </Button>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default RecursoCadastroPage;
